import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import { Container, Row, Col, Card, Button } from "react-bootstrap"
import banner from "../images/bg.jpg"
import * as styles from "../styles/home.module.css"
import useFetch from "../components/useFetch"
import Cardcomp from "../components/Cardcomp"
import useSitedata from "../components/useSitedata"
import { FaPaintBrush } from "@react-icons/all-files/fa/FaPaintBrush"
import Headmeta from "../components/Headmeta"

import "bootstrap/dist/css/bootstrap.min.css"

export default function Home({ data }) {
  // console.log(data);
  var sectionStyle = {
    backgroundImage: `linear-gradient(rgba(4,9,30,0.8),rgba(4,9,30,0.8)),url(${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "95vh",
    width: "100%",
    position: "relative",
    overflow: "hidden",
  }

  const artworksPageName = useSitedata("artworksPageName")
  const projectsPageName = useSitedata("projectsPageName")
  // console.dir(projectsPageName)

  // Whatever projects you want to display on the home page, write the name of their markdown file in the array below.
  // their data shall be stored in projectsData array. you can use this array to display it at relevant places in the code.
  //in this array, ensure to provide the first project name to be the one who shall be stored in the main projects card
  // and the rest of the projects shall be stored in the projects-that-you-dont-want-to-miss cards

  const projectsDisplayHome = ["NCA-Z03", "NCA-Z01", "NCA-Z02", "NCA-Z04"]
  const projectsAll = useFetch(projectsPageName)
  // console.dir(projectsAll);
  //Get data from projects based on projectsDisplayHome
  const projectsData = projectsAll.filter(project =>
    projectsDisplayHome.includes(project.frontmatter.slug)
  )
  // console.dir(projectsData);

  // now lets get the index of our projects in the projectsdata array
  // so that we can use it to get the data of the project we want to display on the home page.
  // this is for the convenience only and not used much in the code. If you change the display projects
  // later, then this would tell you at which index no is your particular project in the projectdata
  // array so that you can change the code quickly.
  const projectsDisplayHomeIndexArray = projectsDisplayHome.map(project =>
    projectsData.findIndex(
      projectData => projectData.frontmatter.slug === project
    )
  )
  // console.dir(projectsDisplayHomeIndexArray);
  // uncomment above to see the index in console and then use these indexes to display project in different sections on the page
  const projectsMainIndex = projectsDisplayHomeIndexArray[0]
  const projectsOtherIndex = projectsDisplayHomeIndexArray.filter(
    index => index !== projectsMainIndex
  )
  // console.dir(projectsOtherIndex);

  // Similarly do it for store data as well

  const storeDisplayHome = ["NCA-L01", "NCA-D01", "NCA-N07", "NCA-A04"]
  const stores = useFetch(artworksPageName)
  const storeData = stores.filter(store =>
    storeDisplayHome.includes(store.frontmatter.slug)
  )
  // console.dir(stores);
  // console.dir(storeData[0].frontmatter.slug);
  const storeDisplayHomeIndexArray = storeDisplayHome.map(store =>
    storeData.findIndex(storeData => storeData.frontmatter.slug === store)
  )
  // console.dir(storeDisplayHomeIndexArray);
  const storeMainIndex = storeDisplayHomeIndexArray[0]
  const storeOtherIndex = storeDisplayHomeIndexArray.filter(
    index => index !== storeMainIndex
  )
  // console.dir(storeOtherIndex);

  return (
    <Layout>
      <Headmeta importedIn="home | Art and Design. Customize new designs or order and explore existing." />
      <div
        style={sectionStyle}
        className="border d-flex align-items-center justify-content-center text-center text-white"
      >
        <Container fluid className={styles.homeStyles}>
          <Row>
            <Col>
              <h1 className="display-3">Art that brings life to your ideas</h1>
              <p className="mt-3">
                Hasslefree, personalized designs for you delivered at your
                doorstep. <br /> Be it a painting, greeting card, sketch,
                wedding card, poster, digital print, and more....
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <Container fluid className={styles.homeStyles}>
        <Row>
          <Col className="text-center mt-5 pt-5">
            <h1 className="display-5">
              Explore the ideas <br />
              that are already alive
            </h1>
            <p className="mt-3 mb-5">
              Hundreds of ideas are already living in our projects and
              heartworks sections. You may customize the existing artworks as
              well. Be it writing "May the force and lots of love be with you"
              on the serene painting you're gifting to your loved ones or a
              stamp of "My precious" on the gift that you have decided to give
              yourself for achieving your goal. We have everything covered for
              you. Since everyone is unique, customization flows through our
              veins.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center text-center">
          <Cardcomp
            cardDataProp={storeData[storeMainIndex].frontmatter}
            compAddedIn={"index" + artworksPageName}
          />
          <Cardcomp
            cardDataProp={projectsData[projectsMainIndex].frontmatter}
            compAddedIn={"index" + projectsPageName}
          />
        </Row>

        <Row>
          <Col className="text-center mt-5 pt-5">
            <h1 className="display-5">
              Wide range of artworks and design services <br />
              that fulfill all your requirements
            </h1>
            <p className="mt-3 mb-5">
              We can not only design a tiny bookmark but paint a big wall as
              well. We can help you in bringing a clear output from your vague
              idea. We are fully committed to what we do and our work is a
              testament to it. Following are some of the many categories that we
              can do the design work in.
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg={4} md={6} sm={12} className="mb-3">
            <Card>
              <Card.Body className="text-center">Painting</Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} className="mb-3">
            <Card>
              <Card.Body className="text-center">Sketch</Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} className="mb-3">
            <Card>
              <Card.Body className="text-center">Wedding Card</Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} className="mb-3">
            <Card>
              <Card.Body className="text-center">Poster</Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} className="mb-3">
            <Card>
              <Card.Body className="text-center">Calendar</Card.Body>
            </Card>
          </Col>
          <Col lg={4} md={6} sm={12} className="mb-3">
            <Card>
              <Card.Body className="text-center">Illustration</Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-5 pt-5">
            <h1 className="display-5">
              <span style={{ color: "rgb(195,98,65)" }}>
                Artworks <br />{" "}
              </span>
              that even artists love
            </h1>

            {/* <h1 className="display-5" style={{ color: "rgb(213,66,131)" }}>
              Artworks
            </h1>
            <h1 className="display-5">that even artists love</h1> */}
            <p className="mt-3 mb-5">
              The chosen ones! Though there are multiple artworks under our belt
              that you would love to see, we have filtered&mdash;a difficult
              task for us&mdash;these artworks which are loved not only by
              non-artists but by artists as well.
            </p>
          </Col>
        </Row>
        {/* <Row className="justify-content-center ps-5 pe-5 ms-lg-5 me-lg-5 text-center"> */}
        <Row className="justify-content-center ps-1 pe-1 text-center">
          <Cardcomp
            cardDataProp={storeData[storeOtherIndex[0]].frontmatter}
            compAddedIn={artworksPageName}
          />
          <Cardcomp
            cardDataProp={storeData[storeOtherIndex[1]].frontmatter}
            compAddedIn={artworksPageName}
          />
          <Cardcomp
            cardDataProp={storeData[storeOtherIndex[2]].frontmatter}
            compAddedIn={artworksPageName}
          />
        </Row>

        <Row>
          <Col className="text-center mt-5 pt-5">
            <h1 className="display-5">
              <span style={{ color: "rgb(195,98,65)" }}>
                Projects <br />{" "}
              </span>
              {/* <span style={{ color: "rgb(195,88,23)" }}>Projects <br /> </span> */}
              {/* <span style={{ color: "rgb(229,103,23)" }}>Projects <br /> </span> */}
              that made our clients ecstatic
            </h1>
            <p className="mt-3 mb-5">
              Truly customized projects! Helped in making a long lasting cordial
              relationship with our clients and memory of their happy faces in
              our heads.{" "}
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center text-center">
          <Cardcomp
            cardDataProp={projectsData[projectsOtherIndex[0]].frontmatter}
            compAddedIn={projectsPageName}
          />
          <Cardcomp
            cardDataProp={projectsData[projectsOtherIndex[1]].frontmatter}
            compAddedIn={projectsPageName}
          />
          <Cardcomp
            cardDataProp={projectsData[projectsOtherIndex[2]].frontmatter}
            compAddedIn={projectsPageName}
          />
        </Row>

        {/* Testimonial section. Commenting this our for now. uncomment when these are available from clients. */}
        {/* <Row>
          <Col className="text-center mt-5 pt-5">
            <h1 className="display-5 text-primary">
              What would people say? <br />
              Let's face it! Let them say
            </h1>
            <p className="p-5">
              From the people who have worked or work with us and are humble
              enough to leave us some feedback.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={4} md={6} sm={12} className="mb-3">
            <Link to="/store" style={{ textDecoration: "none" }}>
              <Card bg="dark" border="dark" className="h-100">
                <Card.Body className="text-center">
                  <Card.Title>Store</Card.Title>
                  <Card.Text>
                    <p>Explore now</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col lg={4} md={6} sm={12} className="mb-3">
            <Link to="/projects" style={{ textDecoration: "none" }}>
              <Card bg="dark" border="dark" className="h-100">
                <Card.Body className="text-center">
                  <Card.Title>Projects</Card.Title>
                  <Card.Text>
                    <p>Explore now</p>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row> */}

        {/* call to action section */}
        <Row>
          <Col className="text-center mt-5 pt-5">
            <h1 className="display-5">To begin, begin</h1>

            <p className="p-1">
              We have sharpened our pencils and our colors are on palette, ready
              to give shape to your ideas.
            </p>
          </Col>
          <Row className="justify-content-center">
            <Col xs="auto">
              <Link to="/contact">
                <Button variant="success outline-dark" className="text-light">
                  <FaPaintBrush /> Let's create something beautiful
                </Button>
              </Link>
            </Col>
          </Row>
        </Row>
      </Container>
    </Layout>
  )
}
